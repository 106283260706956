













































































































































































































































































































































































































































































































































































import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { ClientInfo, ClientInfoDto } from '@/organizations/corporate/models/client-info';
import { LoadingStore } from '@/store/loading-store';
import { ClientRepository } from '@/organizations/corporate/repositories/client-repository';
import { EventTypes } from '@/constants/event-type-constants';
import { ClientInfoMapper } from '@/organizations/corporate/mappers/client-info-mapper';
import isEqual from 'lodash/isEqual';
import { SocialMediaRootURLConstants } from '@/core/models/social-media-link';
import UploadLogo from '@/automation/landing-pages/components/UploadLogo.vue';
import { Logo } from '@/logo/models/logo';
import { LogoMapper } from '@/logo/mappers/logo-mapper';
import { VForm } from '@/types/types';
import SaveButton from '@/components/base/SaveButton.vue';
import { RegionsBusiness } from '@/locales/locales';

const loadingState = getModule(LoadingStore);
const clientInfoRepository = new ClientRepository();
const clientInfoMapper = new ClientInfoMapper();
const logoMapper = new LogoMapper();

@Component({
    components: { UploadLogo, SaveButton }
})
export default class ManageClientInfo extends Mixins(LocaleMixin, BasicValidationMixin) {
    // v-model whether we should show it
    @Prop({ default: false }) readonly value!: boolean;
    @Prop({ required: true }) readonly clientInfo!: ClientInfo | null;
    @Ref('form') readonly form!: VForm;

    private loadingKey = 'clientInfoEditModal';
    private validForm = true;
    private clientInfoDto: ClientInfoDto = new ClientInfoDto();
    private accountInfoPanelIndex: number | undefined = -1;
    private socialMediaLinksPanelIndex: number | undefined = -1;
    private logo: Logo | null = null;
    private panelIndex: number | undefined = -1;
    private maxLength = 200;
    private logoExists = false;
    private logoChange = false;
    private commentRows = 3;
    private originalName = '';
    private descriptionRules = [(v = '') => v.length <= this.maxLength || `Max ${this.maxLength} characters`];
    private facebookRootConstant = SocialMediaRootURLConstants.FACEBOOK;
    private youtubeRootConstant = SocialMediaRootURLConstants.YOUTUBE;
    private linkedinRootConstant = SocialMediaRootURLConstants.LINKEDIN;
    private instagramRootConstant = SocialMediaRootURLConstants.INSTAGRAM;
    private twitterRootConstant = SocialMediaRootURLConstants.TWITTER;
    private pinterestRootConstant = SocialMediaRootURLConstants.PINTEREST;

    private rootParts: Array<string> = [];

    private showUploadDialog = false;
    private descriptionCountDown = (v = '') => {
        return this.maxLength - v.length;
    };

    private jobPositions = ['Director', 'GM', 'VP', 'CEO', 'CFO', 'General Counsel', 'Other'];

    private businessTypes = ['Co-operative', 'Corporation', 'Limited Liability', 'Non-profit Corporation', 'Partnership'];

    private countryOptions = ['US', 'CA', 'AS', 'UK', 'NZ', 'IN'];

    private regionOptions = ['AFRICA', 'ASIA', 'EUROPE', 'LATIN_AMERICA', 'USA_AND_CANADA'];

    private businessRegistrationLabel = 'Business Registration Number';

    private businessNameTooltip = 'This must match exactly the business name on your formation documents to enable Texting and/or Call Recording features.';

    private telephonyTooltip = 'Required to enable Texting and/or Call Recording features.'

    // Getters and Setters
    get modelValue(): boolean {
        return this.value;
    }

    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit('input', showIt);
    }

    // Watches (not clocks)
    @Watch('modelValue')
    private async doOpen() {
        if (this.modelValue) {
            if (this.clientInfo !== null) {

                this.clientInfoDto = clientInfoMapper.toUpdateDto(this.clientInfo);
                // Let the DOM update, before trying to validate, or it won't validate correctly.
                this.originalName = this.clientInfo.name;
                this.rootParts.push('http://', 'https://');
                this.rootParts.push(this.facebookRootConstant, this.youtubeRootConstant, this.pinterestRootConstant, this.twitterRootConstant, this.instagramRootConstant, this.linkedinRootConstant);
                this.rootParts.push(this.facebookRootConstant.replaceAll('www.', ''), this.youtubeRootConstant.replaceAll('www.', ''),
                                    this.pinterestRootConstant.replaceAll('www.', ''), this.twitterRootConstant.replaceAll('www.', ''),
                                    this.instagramRootConstant.replaceAll('www.', ''), this.linkedinRootConstant.replaceAll('www.', ''));
                this.logo = await clientInfoRepository.getLogo();
                this.logoExists = !!this.logo;
                this.$nextTick(() => {
                    // Validate form when we popup the modal.
                    this.form.validate();
                });

                this.updateByCountry();
            }

        }
    }

    // Computed
    get hasChanges(): boolean {
        return (this.clientInfo !== null &&
            (
               !isEqual(this.clientInfoDto, clientInfoMapper.toUpdateDto(this.clientInfo))
            )
        );
    }

    // Life cycle
    async created() {
        // Handle key presses (ESC)
        document.addEventListener('keyup', this.handleKeyup);
    }

    private async deleteLogo() {
        const result = await this.$swal({
            text: 'Are you sure you want to delete the logo for this organization?',
            showConfirmButton: true,
            showCancelButton: true
        });
        if (result.isConfirmed) {
            this.logo = null;
        }
        this.logoChange = this.logoExists;
    }

    // Methods
    private handleKeyup(e: any): void {
        if (e.keyCode === 27 && this.modelValue) {
            // Escape out of editing mode.
            this.modelValue = false;
            this.accountInfoPanelIndex = -1;
            this.socialMediaLinksPanelIndex = -1;
            this.rootParts = [];
        }
    }

    private handleClose() {
        this.form.reset();
        this.logoChange = false;
        this.logoExists = false;
        this.logo = null;
        this.panelIndex = -1;
        this.accountInfoPanelIndex = -1;
        this.socialMediaLinksPanelIndex = -1;
        this.rootParts = [];
        this.modelValue = false;
    }

    private logoUploaded(logo: Logo) {
        this.logo = logo;
        this.showUploadDialog = false;
        this.logoChange = true;
    }

    private updateByCountry() {
        this.updateBusinessRegistrationLabel();
        this.updateBusinessNameTooltip();
        this.updateTelephonyTooltip();
    }

    private updateBusinessRegistrationLabel() {
        const country = this.clientInfoDto.iso_country;
        switch (country) {
            case RegionsBusiness.US:
                this.businessRegistrationLabel = 'Employer Identification Number (EIN)';
                break;
            case RegionsBusiness.CA:
                this.businessRegistrationLabel = 'Business Number (BN)';
                break;
            case RegionsBusiness.AU:
                this.businessRegistrationLabel = 'Australian Business Number (ABN)';
                break;
            case RegionsBusiness.UK:
                this.businessRegistrationLabel = 'Unique Taxpayer Reference (UTR)';
                break;
            case RegionsBusiness.NZ:
                this.businessRegistrationLabel = 'New Zealand Business Number (NZBN)';
                break;
            case RegionsBusiness.IN:
                this.businessRegistrationLabel = 'Permanent Account Number (PAN)';
                break;
            default:
                this.businessRegistrationLabel = 'Business Registration Number';
        }
    }

    private updateBusinessNameTooltip() {
        const country = this.clientInfoDto.iso_country;
        switch (country) {
            case RegionsBusiness.US:
                this.businessNameTooltip = 'This must match exactly the business name on the formation documents submitted to your Secretary of State to enable Texting and/or Call Recording features.';
                break;
            case RegionsBusiness.CA:
                this.businessNameTooltip = 'This must match exactly the business name on the formation documents submitted to Corporations Canada to enable Texting and/or Call Recording features.';
                break;
            case RegionsBusiness.AU:
                this.businessNameTooltip = 'This must match exactly the business name on the formation documents submitted to the Australian Securities & Investments Commission to enable Texting and/or Call Recording features.';
                break;
            case RegionsBusiness.UK:
                this.businessNameTooltip = 'This must match exactly the business name on the formation documents submitted to Companies House to enable Texting and/or Call Recording features.';
                break;
            case RegionsBusiness.NZ:
                this.businessNameTooltip = 'This must match exactly the business name on the formation documents submitted to the Companies Office to enable Texting and/or Call Recording features.';
                break;
            default:
                this.businessNameTooltip = 'This must match exactly the business name on your formation documents to enable Texting and/or Call Recording features.';
                break;
        }
    }

    private updateTelephonyTooltip() {
        const country = this.clientInfoDto.iso_country;
        switch (country) {
            case RegionsBusiness.US:
                this.telephonyTooltip = 'Required to enable Texting and/or Call Recording features. Note: Include the dash.';
                break;
            case RegionsBusiness.AU:
                this.telephonyTooltip = 'Required to enable Texting and/or Call Recording features. Note: Include the spaces.';
                break;
            case RegionsBusiness.UK:
                this.telephonyTooltip = 'Required to enable Texting and/or Call Recording features. Note: Include spaces if your number has them.';
                break;
            default:
                this.telephonyTooltip = 'Required to enable Texting and/or Call Recording features.';
                break;
        }
    }

    private async save() {
        loadingState.loadingIncrement(this.loadingKey);
        try {
            this.rootParts.forEach(root => {
                if (this.clientInfoDto.social_media.facebook.indexOf(root) !== -1) {
                    this.clientInfoDto.social_media.facebook = this.clientInfoDto.social_media.facebook.replaceAll(root, '');
                }

                if (this.clientInfoDto.social_media.youtube.indexOf(root) !== -1) {
                    this.clientInfoDto.social_media.youtube = this.clientInfoDto.social_media.youtube.replaceAll(root, '');
                }

                if (this.clientInfoDto.social_media.linkedin.indexOf(root) !== -1) {
                    this.clientInfoDto.social_media.linkedin = this.clientInfoDto.social_media.linkedin.replaceAll(root, '');
                }

                if (this.clientInfoDto.social_media.instagram.indexOf(root) !== -1) {
                    this.clientInfoDto.social_media.instagram = this.clientInfoDto.social_media.instagram.replaceAll(root, '');
                }

                if (this.clientInfoDto.social_media.twitter.indexOf(root) !== -1) {
                    this.clientInfoDto.social_media.twitter = this.clientInfoDto.social_media.twitter.replaceAll(root, '');
                }

                if (this.clientInfoDto.social_media.pinterest.indexOf(root) !== -1) {
                    this.clientInfoDto.social_media.pinterest = this.clientInfoDto.social_media.pinterest.replaceAll(root, '');
                }

            });
            await clientInfoRepository.updateClientInfo(this.clientInfoDto);
            if (this.logoExists && (this.logo === null)) {
                await clientInfoRepository.deleteLogo();
            } else if (this.logo) {
                await clientInfoRepository.uploadLogo(logoMapper.toCreateDto(this.logo));
            }
        } catch (e) {
            // Do nothing!
        }
        if (this.originalName !== this.clientInfoDto.name) {
            await this.$router.go(0);
        }

        loadingState.loadingDecrement(this.loadingKey);

        // Emit that we updated something.
        this.$emit(EventTypes.UPDATED);

        // Close
        this.handleClose();
    }

    private nonRequiredEmail = [
        (v: string) => {
            if (v.length > 0) {
                return /.+@.+\..*/.test(v) || 'Please enter valid email address';
            }

            return true;
        }
    ];
}
