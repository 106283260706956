
























































































































































































































































































import { SortConstants } from '@/constants/sort-constants';
import { LocaleMixin } from '@/locales/locale-mixin';
import Brands from '@/organizations/brands/components/Brands.vue';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { ClientRepository } from '@/organizations/corporate/repositories/client-repository';
import store from '@/store';
import { AuthStore } from '@/store/auth-store';
import { LoadingStore } from '@/store/loading-store';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { Center, CenterSortKeys, CenterSortParameter } from '@/organizations/locations/models/center';
import { DataTableHeader } from 'vuetify';
import { TimezonesStore } from '@/core/timezones/timezones-store';
import { Timezone } from '@/core/timezones/timezone';
import { EventTypes } from '@/constants/event-type-constants';
import { ClientInfo } from '@/organizations/corporate/models/client-info';
import ManageLocation from '@/organizations/locations/components/ManageLocation.vue';
import ManageClientInfo from '@/organizations/corporate/components/ManageClientInfo.vue';
import ReportingLevelCard from '@/organizations/views/ReportingLevelCard.vue';
import { Org, OrgTreeNode } from '@/models/organization/org';
import { OrganizationLevel } from '@/organizations/levels/models/org-level-models';
import { LevelTypes } from '@/organizations/levels/level-constants';
import { OrgLevelsStore } from '@/organizations/levels/store/org-levels-store';
import { OrgsStore } from '@/store/orgs-store';
import ManageReportingLevel from '@/organizations/levels/components/ManageReportingLevel.vue';
import { OrgLevelsRepository } from '@/organizations/levels/repositories/org-levels-repository';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import LocationGroups from '@/organizations/location-groups/view/LocationGroups.vue';
import OrganizationInformationSettings from '@/organizations/views/OrganizationInformationSettings.vue';
import { PermissionName } from '@/staff/models/user-permission-models';
import { UserPermissionsStore } from '@/staff/store/user-permissions-store';
import Locations from '@/organizations/locations/views/Locations.vue';
import BasePageTitle from '@/components/base/BasePageTitle.vue';
import { PageTitleMixin } from '@/core/page-title-mixin';

const authState = getModule(AuthStore, store);
const loadingState = getModule(LoadingStore);
const centersStore = getModule(CentersStore);
const timezonesStore = getModule(TimezonesStore);
const clientInfoRepository = new ClientRepository();
const orgLevelsStore = getModule(OrgLevelsStore);
const orgsState = getModule(OrgsStore);
const levelsRepo = new OrgLevelsRepository();
const featureStore = getModule(FeaturesStore);
const userPermissionsState = getModule(UserPermissionsStore);

@Component({
    components: {
        BasePageTitle,
        Brands,
        LocationGroups,
        Locations,
        ManageClientInfo,
        ManageLocation,
        ManageReportingLevel,
        ReportingLevelCard,
        OrganizationInformationSettings
    }
})
export default class Organization extends Mixins(LocaleMixin, PageTitleMixin) {
    $refs!: {
        orgTreeView: any;
    };

    private addLocationClickedEvent = EventTypes.ADD_LOCATION_CLICKED;
    private loadingKey = 'organizationSettings';
    private clientInfoLoadingKey = 'clientInfo';
    private defaultSorting: CenterSortParameter = {
        sort_keys: [CenterSortKeys.NAME],
        sort_dir: [SortConstants.ASCENDING]
    };

    private locationLoadingKey = 'location';
    private reportingLevelKey = 'reportingLevel';
    private isEditingClientInfo = false;
    private updatedClientInfoEvent = EventTypes.UPDATED;
    private updatedLocationEvent = EventTypes.UPDATED;
    private editLocationEvent = EventTypes.ADDED;
    private editLocationClickedEvent = EventTypes.EDIT_LOCATION_CLICKED;
    private locationsComponentKey = 0;
    private manageActivated = false;
    private managedLocation: Center | null = null;
    private orgLevels: Array<OrganizationLevel> = [];
    private orgLevelsTreeView: Array<OrganizationLevel> = [];
    private allOrgs: Array<Org> = [];
    private allOpened = false;
    private openedItems: Array<number> = [];
    private firstOpened: Array<number> = [];
    private switchToTreeView = false;
    private manageLevelActivated = false;
    private showOrgInfoSettings = false;
    private createdLevelEvent = EventTypes.UPDATED;
    private updatedEvent = EventTypes.UPDATED;

    private clientInfo: ClientInfo = {
        name: '',
        address: {
            address1: '',
            locality: '',
            region: '',
            postcode: '',
            country: ''
        },
        phone: {
            number_e164: ''
        },
        url: '',
        marketing_staff: null,
        admin_staff: null,
        billing_account_name: '',
        billing_address: {
            address1: '',
            locality: '',
            region: '',
            postcode: '',
            country: ''
        },
        billing_contact_first_name: '',
        billing_contact_last_name: '',
        billing_contact_job_title: '',
        billing_contact_job_position: '',
        business_type: '',
        iso_country: '',
        business_regions_of_operation: '',
        billing_contact_phone: {
            number_e164: ''
        },
        billing_contact_email: '',
        sales_contact_first_name: '',
        sales_contact_last_name: '',
        sales_contact_phone: {
            number_e164: ''
        },
        sales_contact_email: '',
        comments: '',
        is_transitioned: false,
        social_media: {
            facebook: '',
            twitter: '',
            youtube: '',
            linkedin: '',
            instagram: '',
            pinterest: '',
            yelp: null
        },
        business_registration_identifier: '',
        legal_business_name: ''
    };

    private hasSettingsPermission = false;

    // Computed

    private get canSeeBrands(): boolean {
        return this.hasCrmPlus && featureStore.isFeatureEnabled(FeatureConstants.BRANDS) && (this.isCorporateUser || this.isSuperUser);
    }

    private get isLineLeaderEnroll(): boolean {
        return featureStore.isLineLeaderEnroll;
    }

    private get isLocationGroupsFeatureEnabled(): boolean {
        return this.hasCrmPlus && featureStore.isFeatureEnabled(FeatureConstants.LOCATION_GROUPS);
    }

    private get isFranchiseModeFeatureEnabled(): boolean {
        return this.hasCrmPlus && featureStore.isFeatureEnabled(FeatureConstants.FRANCHISE_MODE);
    }

    private get isLocationMergeFieldsFeatureEnabled(): boolean {
        return featureStore.isFeatureEnabled(FeatureConstants.LOCATION_MERGE_FIELDS);
    }

    get orgTreeNodes(): Array<OrgTreeNode> {
        return [orgsState.orgsMap];
    }

    get isCorporateUser(): boolean {
        return authState.isCorporateUser;
    }

    get isSuperUser(): boolean {
        return authState.isSuperuser;
    }

    get isCenterUser(): boolean {
        return authState.isCenterStaff;
    }

    get clientInfoHeaders(): Array<DataTableHeader> {
        const headers = [
            {
                text: 'Organization Name',
                value: 'name'
            },
            {
                text: 'Street Address',
                value: 'address.address1'
            },
            {
                text: 'City',
                value: 'address.locality'
            },
            {
                text: this.$t('regionLabelAbv').toString(),
                value: 'address.region'
            },
            {
                text: this.$t('postcodeLabel').toString(),
                value: 'address.postcode'
            },
            {
                text: 'Phone',
                value: 'phone'
            },
            {
                text: 'Web Site',
                value: 'url'
            }
        ];

        if (this.isCorporateUser || this.isSuperUser) {
            headers.push({
                text: '',
                value: 'action'
            });
        }

        return headers;
    }

    get clientInfoArray(): Array<ClientInfo> {
        return [this.clientInfo];
    }

    get hasCrmPlus(): boolean {
        return featureStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    get showLocationGroups(): boolean {
        if (!this.switchToTreeView && this.isLocationGroupsFeatureEnabled) {
            return (this.isCorporateUser || this.isSuperUser) || (!this.isCenterUser && this.isFranchiseModeFeatureEnabled);
        }
        return false;
    }

    get timezones(): Array<Timezone> {
        return timezonesStore.stored;
    }

    // Life cycle
    async created() {
        loadingState.loadingIncrement(this.locationLoadingKey);

        const clientInfoPromise = this.loadClientInfo();

        const orgLevelsPromise = orgLevelsStore.init();
        const orgsPromise = orgsState.init();
        const featuresPromise = featureStore.init();
        await orgLevelsPromise;
        await orgsPromise;
        await featuresPromise;
        this.orgLevelsTreeView = orgLevelsStore.stored;
        this.updateAllOrgs();
        this.updateOrgLevels();
        this.updateOpenedDefaultItems();

        await clientInfoPromise;
        if (this.hasCrmPlus && this.isLocationMergeFieldsFeatureEnabled) {
            if (this.isSuperUser) {
                this.hasSettingsPermission = true;
            } else {
                const perm = await userPermissionsState.getPermissionByName({
                    userId: authState!.id as number,
                    permissionName: PermissionName.SettingsAllGears
                });
                this.hasSettingsPermission = perm?.grants.update ?? false;
            }
        }
        loadingState.loadingDecrement(this.locationLoadingKey);
    }

    @Watch('switchToTreeView')
    updateTitle() {
        this.setPageTitle(this.switchToTreeView ? 'Organizational Tree' : 'Organization Information');
    }

    mounted() {
        this.updateTitle();
    }

    private async loadClientInfo(): Promise<void> {
        loadingState.loadingIncrement(this.clientInfoLoadingKey);
        this.clientInfo = await clientInfoRepository.getClientInfo();
        loadingState.loadingDecrement(this.clientInfoLoadingKey);
    }

    private updateOrgLevels() {
        this.orgLevels.splice(0);
        const levelsResponse: Array<OrganizationLevel> = orgLevelsStore.stored;
        levelsResponse.forEach(element => {
            if (!(element.id === LevelTypes.CORPORATE || element.id === LevelTypes.CENTER)) {
                this.orgLevels.push(element);
            }
        });
    }

    private updateAllOrgs() {
        this.allOrgs.splice(0);
        this.allOrgs = orgsState.stored;
    }

    private updateOpenedDefaultItems() {
        this.openedItems = [];
        this.openedItems.push(orgsState.rootOrgId!);
        this.firstOpened = this.openedItems;
    }

    private async refreshReportingLevelCards() {
        loadingState.loadingIncrement(this.reportingLevelKey);
        this.orgLevels.splice(0);
        const levelsResponse = await levelsRepo.getAll();
        const levelsResults = levelsResponse.entities;
        levelsResults.forEach(element => {
            if (!(element.id === LevelTypes.CORPORATE || element.id === LevelTypes.CENTER)) {
                this.orgLevels.push(element);
            }
        });

        loadingState.loadingDecrement(this.reportingLevelKey);
    }

    // Methods
    private async refreshLocations() {
        const accessiblePromise = centersStore.retrieveAccessibleCenters();
        await accessiblePromise;
        ++this.locationsComponentKey;
    }

    private async refreshClientInfo() {
        loadingState.loadingIncrement(this.clientInfoLoadingKey);
        this.clientInfo = await clientInfoRepository.getClientInfo();
        loadingState.loadingDecrement(this.clientInfoLoadingKey);
    }

    private editClientInfo(): void {
        this.isEditingClientInfo = true;
    }

    private addLocation() {
        this.managedLocation = null;
        this.manageActivated = true;
    }

    private mappingLevel(id: number): string {
        let resultString = '';
        for (const orgLevel of this.orgLevelsTreeView) {
            if (orgLevel.id === id) {
                resultString = orgLevel.name;
                if (orgLevel.id === id) {
                    return orgLevel.name;
                }
                break;
            }
        }
        return resultString;
    }

    private async editLocation(location: Center | Array<Org>) {
        if (Array.isArray(location)) {
            let tempCenter: Center | null = null;
            if (location[0].center) {
                loadingState.loadingIncrement(this.clientInfoLoadingKey);
                tempCenter = await centersStore.getById(location[0].center.id);
                loadingState.loadingDecrement(this.clientInfoLoadingKey);
            }
            this.managedLocation = tempCenter;
            this.manageActivated = !(tempCenter === null);
        } else {
            this.managedLocation = location;
            this.manageActivated = true;
        }
    }

    private addReportingLevel() {
        this.manageLevelActivated = true;
    }

    private isCenterLevel(orgTreeNode: OrgTreeNode): boolean {
        return orgTreeNode.level === LevelTypes.CENTER;
    }

    private expand() {
        if (!this.allOpened) {
            this.allOpened = true;
            this.$refs.orgTreeView.updateAll(true);
        }
    }

    private collapse() {
        if (this.allOpened) {
            this.$refs.orgTreeView.updateAll(false);
            this.allOpened = false;
            this.openedItems = this.firstOpened;
        }
    }
}
