import { LocaleDefinitions } from '@/locales/locale-definition';
import { locale as usLocale } from './definitions/en-US';
import { locale as caLocale } from './definitions/en-CA';
import { locale as auLocale } from './definitions/en-AU';
import { locale as nzLocale } from './definitions/en-NZ';
import { locale as gbLocale } from './definitions/en-GB';

export const baseLocales: LocaleDefinitions = {
    'en-US': usLocale,
    'en-GB': gbLocale,
    'en-NZ': nzLocale,
    'en-AU': auLocale,
    'en-CA': caLocale
};

export const serversForLocale: { [key: string]: string } = {
    'en-US': 'https://my.childcarecrm.com',
    'en-CA': 'https://my.childcarecrm.com',
    'en-AU': 'https://my.childcarecrm.com.au',
    'en-NZ': 'https://my.childcarecrm.com.au',
    'en-GB': 'https://my.childcarecrm.uk'
};

export const countryToLocaleMap: { [key: string]: string } = {};

for (const [localeName, locale] of Object.entries(baseLocales)) {
    countryToLocaleMap[locale.crmCountry] = localeName;
}

export enum Regions {
    'us',
    'aus',
    'nz',
    'ca',
    'uk'
}

export enum RegionsBusiness {
    US = 'US',
    CA = 'CA',
    AU = 'AU',
    UK = 'UK',
    NZ = 'NZ',
    GB = 'GB',
    IN = 'IN',
}
